import { useContext } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Redux
import { useSelector } from 'react-redux';
import {
  selectActivitiesAddStatus,
  selectActivitiesUpdateStatus,
  selectActivitiesUpdateEndingStatus,
} from 'redux/features/activitiesSlice/utils/selectors';
// Components/ui
import { DialogActions, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Icons
import { EditCalendar, EventAvailable } from '@mui/icons-material';
// Context
import { CalendarContext } from 'features/Views/CalendarView/context/CalendarContext';

type ActionButtonsProps = {
  isFinalized: boolean;
};
const ActionButtons = ({ isFinalized }: ActionButtonsProps) => {
  const {
    eventDialog: { handleClose },
    handleCreateEvent,
    eventDialogMode,
    handleStartEditingEvent,
    handleSaveUpdatedEvent,
    handleEndEvent,
  } = useContext(CalendarContext);
  const { t } = useTranslation();
  const activitiesAddStatus = useSelector(selectActivitiesAddStatus);
  const activitiesUpdateStatus = useSelector(selectActivitiesUpdateStatus);
  const activitiesUpdateEndingStatus = useSelector(
    selectActivitiesUpdateEndingStatus
  );
  const isLoadingAdd = activitiesAddStatus === 'loading';
  const isLoadingUpdate = activitiesUpdateStatus === 'loading';
  const isLoadingEnding = activitiesUpdateEndingStatus === 'loading';

  const onClick =
    (eventDialogMode === 'view' && handleStartEditingEvent) ||
    (eventDialogMode === 'edit' && handleSaveUpdatedEvent) ||
    (eventDialogMode === 'create' && handleCreateEvent);

  const ButtonText =
    (eventDialogMode === 'view' && t('global.editEvent')) ||
    (eventDialogMode === 'edit' && t('global.saveEvent')) ||
    (eventDialogMode === 'create' && t('global.createEvent'));

  return (
    <DialogActions>
      <Button variant="outlined" size="small" onClick={handleClose} color="secondary">
        {t('global.cancel')}
      </Button>
      {(eventDialogMode === 'edit' || eventDialogMode === 'view') && (
        <LoadingButton
          startIcon={<EventAvailable />}
          size="small"
          disabled={isLoadingEnding || isFinalized}
          loading={isLoadingEnding}
          onClick={handleEndEvent}
          variant="contained"
        >
          {t('global.finalizeEvent')}
        </LoadingButton>
      )}
      <LoadingButton
        startIcon={<EditCalendar />}
        size="small"
        loading={isLoadingAdd || isLoadingUpdate}
        disabled={isLoadingAdd || isLoadingUpdate || isFinalized}
        onClick={onClick}
        variant="contained"
      >
        {ButtonText}
      </LoadingButton>
    </DialogActions>
  );
};

export default ActionButtons;
