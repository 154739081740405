import { createSlice, createAsyncThunk, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { initRequestData } from '../../functions/initRequestData';
// Types
import { RootState } from '../../store';
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { ContactInfoState } from './types/ContactInfoState';
import { IContact, IContactField, IFile, INote } from '@trii/types/dist/Contacts';
import { ContactData } from './types/ContactData';
import { Pagination } from './types/Pagination';
import { FieldsData } from './types/FieldsData';
import { ContactNotes } from './types/ContactNotes';
import { ContactFile } from './types/ContactFile';
import { UpdateField } from './types/UpdateField';
// Service
import contactInfoService from './contactInfoService';
import { ConvertContact } from './types/ConvertContact';

const initialState: ContactInfoState = {
  contactInfo: null,
  contacts: null,
  contactFields: null,
  newNote: null,
  notes: [],
  files: [],
  status: {
    fetch: 'idle',
    contacts: 'idle',
    contactFields: 'idle',
    newNote: 'idle',
    notes: 'idle',
    newFile: 'idle',
    files: 'idle',
  },
};

export const fetchContactInfo = createAsyncThunk(
  'ContactInfo/fetchContactInfo',
  async (userId: string, { dispatch }) => {
    const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const response = await contactInfoService.fetchContactInfo(
      jwtToken,
      URL_CONTACTS,
      userId
    );

    return response;
  }
);

export const fetchContactsData = createAsyncThunk<
  ContactData,
  Pagination,
  { state: RootState }
>('contacts/fetchContactsTableData', async (
  paginationFetchData, { dispatch }
) => {
  const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;

  const contactTableData = await contactInfoService.fetchContacts(
    jwtToken,
    URL_CONTACTS,
    paginationFetchData
  );
  return contactTableData;
});

export const fetchFields = createAsyncThunk<
  IContactField[],
  FieldsData
>(
  'contacts/fetchFields', async (
    data,
    { dispatch }
  ) => {
  const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;
  const { filterBy, fetchFor } = data;

  const response = await contactInfoService.fetchFields(
    jwtToken,
    URL_CONTACTS,
    filterBy,
    fetchFor
  );

  return response;
});

export const fetchNewNote = createAsyncThunk(
  'contacts/fetchNewNote', async (
    data: ContactNotes,
    { dispatch }
  ) => {
  const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;

  const response = await contactInfoService.fetchNewNote(
    jwtToken,
    URL_CONTACTS,
    data
  );

  return response;
});

export const fetchNotes = createAsyncThunk(
  'contacts/fetchNotes', async (
    contactId: string,
    { dispatch }
  ) => {
  const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;

  const response = await contactInfoService.fetchNotes(
    jwtToken,
    URL_CONTACTS,
    contactId
  );

  return response;
});

export const fetchUpdateNote = createAsyncThunk(
  'contacts/fetchUpdateNote', async (
    data: ContactNotes,
    { dispatch }
  ) => {
  const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;

  const response = await contactInfoService.fetchUpdateNote(
    jwtToken,
    URL_CONTACTS,
    data
  );

  return response;
});

export const fetchDeleteNote = createAsyncThunk(
  'contacts/fetchDeleteNote', async (
    data: ContactNotes,
    { dispatch }
  ) => {
  const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;

  const response = await contactInfoService.fetchDeleteNote(
    jwtToken,
    URL_CONTACTS,
    data
  );

  return response;
});

export const fetchNewFile = createAsyncThunk(
  'contacts/fetchNewFile', async (
    data: ContactFile,
    { dispatch }
  ) => {
  const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;

  const response = await contactInfoService.fetchNewFile(
    jwtToken,
    URL_CONTACTS,
    data
  );

  return response;
});

export const fetchFiles = createAsyncThunk(
  'contacts/fetchFiles', async (
    contactId: string,
    { dispatch }
  ) => {
  const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;

  const response = await contactInfoService.fetchFiles(
    jwtToken,
    URL_CONTACTS,
    contactId
  );

  return response;
});

export const fetchDeleteFile = createAsyncThunk(
  'contacts/fetchDeleteFile', async (
    {
      contactId,
      fileId,
    }: {
      contactId: string;
      fileId: string;
    },
    { dispatch }
  ) => {
  const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;

  const response = await contactInfoService.fetchDeleteFile(
    jwtToken,
    URL_CONTACTS,
    fileId,
    contactId
  );

  return response;
});

export const fetchUpdateField = createAsyncThunk(
  'contacts/fetchUpdateField', async (
    data: UpdateField,
    { dispatch }
  ) => {
  const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;

  const response = await contactInfoService.fetchUpdateField(
    jwtToken,
    URL_CONTACTS,
    data
  );

  return response;
});

export const fetchConvertContactToBusiness = createAsyncThunk(
  'contacts/fetchConvertContactToBusiness', async (
    data: ConvertContact,
    { dispatch }
  ) => {
  const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;

  const response = await contactInfoService.fetchConvertContactToBusiness(
    jwtToken,
    URL_CONTACTS,
    data
  );

  return response;
}
);

export const fetchConvertBusinessToContact = createAsyncThunk(
  'contacts/fetchConvertBusinessToContact', async (
    data: ConvertContact,
    { dispatch }
  ) => {
  const { jwtToken, URL_CONTACTS } = (await dispatch(initRequestData()))
    .payload as InitRequestDataReturn;

  const response = await contactInfoService.fetchConvertBusinessToContact(
    jwtToken,
    URL_CONTACTS,
    data
  );

  return response;
}
);


const contactInfoSlice = createSlice({
  name: 'contactInfo',
  initialState,
  reducers: {
    resetContactInfo: (state) => {
      state.contactInfo = null;
      state.status.fetch = 'idle';
    },
    resetContacts: (state) => {
      state.contacts = null;
      state.status.contacts = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContactInfo.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(
        fetchContactInfo.fulfilled,
        (state, action: PayloadAction<IContact>) => {
          state.contactInfo = action.payload;
          state.status.fetch = 'succeeded';

          console.log('fetchContactInfo succeeded: ', action.payload);
        }
      )
      .addCase(fetchContactInfo.rejected, (state, action) => {
        state.status.contacts = 'rejected';
        state.contactInfo = null;
      })
      .addCase(fetchContactsData.pending, (state) => {
        state.status.contacts = 'loading';
      })
      .addCase(
        fetchContactsData.fulfilled,
        (state, action: PayloadAction<ContactData>) => {
          state.contacts = action.payload;
          state.status.contacts = 'succeeded';

          console.log('fetchContactsData succeeded: ', action.payload);
        }
      )
      .addCase(fetchContactsData.rejected, (state, action) => {
        state.status.contacts = 'rejected';
        state.contacts = null;
      })
      .addCase(fetchFields.pending, (state) => {
        state.status.contactFields = 'loading';
      })
      .addCase(
        fetchFields.fulfilled,
        (state, action: PayloadAction<IContactField[]>) => {
          state.contactFields = action.payload;
          state.status.contactFields = 'succeeded';

          console.log('fetchFields succeeded: ', action.payload);
        }
      )
      .addCase(fetchFields.rejected, (state, action) => {
        state.status.contactFields = 'rejected';
        state.contactFields = null;
      })
      .addCase(fetchNewNote.pending, (state) => {
        state.status.newNote = 'loading';
      })
      .addCase(
        fetchNewNote.fulfilled,
        (state, action: PayloadAction<INote>) => {
          state.newNote = action.payload;
          state.status.newNote = 'succeeded';

          console.log('fetchNewNote succeeded: ', action.payload);
        }
      )
      .addCase(fetchNewNote.rejected, (state, action) => {
        state.status.newNote = 'rejected';
        state.newNote = null;
      })
      .addCase(fetchNotes.pending, (state) => {
        state.status.notes = 'loading';
      })
      .addCase(
        fetchNotes.fulfilled,
        (state, action: PayloadAction<INote[]>) => {
          state.notes = action.payload;
          state.status.notes = 'succeeded';

          console.log('fetchNotes succeeded: ', action.payload);
        }
      )
      .addCase(fetchNotes.rejected, (state, action) => {
        state.status.notes = 'rejected';
        state.notes = null;
      })
      .addCase(fetchUpdateNote.pending, (state) => {
        state.status.newNote = 'loading';
      })
      .addCase(
        fetchUpdateNote.fulfilled,
        (state, action: PayloadAction<INote>) => {
          state.newNote = action.payload;
          state.status.newNote = 'succeeded';

          console.log('fetchUpdateNote succeeded: ', action.payload);
        }
      )
      .addCase(fetchUpdateNote.rejected, (state, action) => {
        state.status.newNote = 'rejected';
        state.newNote = null;
      })
      .addCase(fetchNewFile.pending, (state) => {
        state.status.newFile = 'loading';
      })
      .addCase(
        fetchNewFile.fulfilled,
        (state, action: PayloadAction<IFile>) => {
          state.status.newFile = 'succeeded';

          console.log('fetchNewFile succeeded: ', action.payload);
        }
      )
      .addCase(fetchNewFile.rejected, (state, action) => {
        state.status.newFile = 'rejected';
      })
      .addCase(fetchFiles.pending, (state) => {
        state.status.files = 'loading';
      })
      .addCase(
        fetchFiles.fulfilled,
        (state, action: PayloadAction<IFile[]>) => {
          state.files = action.payload;
          state.status.files = 'succeeded';

          console.log('fetchFiles succeeded: ', action.payload);
        }
      )
      .addCase(fetchFiles.rejected, (state, action) => {
        state.status.fetch = 'rejected';
      })
      .addCase(fetchUpdateField.pending, (state) => {
        state.status.fetch = 'loading';
      })
      .addCase(
        fetchUpdateField.fulfilled,
        (state, action: PayloadAction<IContact>) => {
          state.status.files = 'succeeded';

          console.log('fetchUpdateField succeeded: ', action.payload);
        }
      )
      .addCase(fetchUpdateField.rejected, (state, action) => {
        state.status.files = 'rejected';
      })
      .addCase(fetchConvertContactToBusiness.pending, (state) => {
        console.log('fetchConvertContactToBusiness loading: ', state);

      })
      .addCase(
        fetchConvertContactToBusiness.fulfilled,
        (state, action: PayloadAction<IContact>) => {
   //@ts-ignore
   state.contactInfo = action.payload;
          console.log('fetchConvertContactToBusiness succeeded: ', action.payload);
        }
      )
      .addCase(fetchConvertContactToBusiness.rejected, (state, action) => {
     
        console.log('fetchConvertContactToBusiness rejected: ', state);

      })
      .addCase(fetchConvertBusinessToContact.pending, (state) => {
        console.log('fetchConvertContactToBusiness loading: ', state);

      })
      .addCase(
        fetchConvertBusinessToContact.fulfilled,
        (state, action: PayloadAction<IContact>) => {
          //@ts-ignore
          state.contactInfo = action.payload;
          console.log('fetchConvertContactToBusiness succeeded: ', state);
        }
      )
      .addCase(fetchConvertBusinessToContact.rejected, (state, action) => {
        console.log('fetchConvertContactToBusiness rejected: ', state);

      });
  },
});

const contactInfoState = (state: RootState) => state.ContactInfo;
export const selectContactInfo = createSelector(
  contactInfoState,
  (state) => state.contactInfo
);
export const selectContactInfoFetchStatus = createSelector(
  contactInfoState,
  (state) => state.status.fetch
);
export const selectContacts = createSelector(
  contactInfoState,
  (state) => state.contacts
);
export const selectContactsFetchStatus = createSelector(
  contactInfoState,
  (state) => state.status.contacts
);
export const selectContactFields = createSelector(
  contactInfoState,
  (state) => state.contactFields
);
export const selectContactFieldsFetchStatus = createSelector(
  contactInfoState,
  (state) => state.status.contactFields
);
export const selectNewNote = createSelector(
  contactInfoState,
  (state) => state.newNote
);
export const selectNewNoteFetchStatus = createSelector(
  contactInfoState,
  (state) => state.status.newNote
);
export const selectNotes = createSelector(
  contactInfoState,
  (state) => state.notes
);
export const selectNotesFetchStatus = createSelector(
  contactInfoState,
  (state) => state.status.notes
);
export const selectNewFileFetchStatus = createSelector(
  contactInfoState,
  (state) => state.status.newFile
);
export const selectFiles = createSelector(
  contactInfoState,
  (state) => state.files
);
export const selectFilesFetchStatus = createSelector(
  contactInfoState,
  (state) => state.status.files
);

// Actions
export const { resetContactInfo, resetContacts } = contactInfoSlice.actions;

export default contactInfoSlice.reducer;
