import { createContext } from 'react';
// Types
import { UseDialog, useDialogInitialState } from '../hooks/useDialog';
import { UseEvent, useEventInitialState } from '../hooks/useEvent';
import { UseCalendarType, useCalendarInitialState } from '../hooks/useCalendar';
import {
  UseCalendarEventsType,
  useCalendarEventsInitialState,
} from '../hooks/useCalendarEvents';
import { Mode } from './types/Mode';

export type CalendarContextType = {
  eventDialog: UseDialog;
  createCalendarDialog: UseDialog;
  event: UseEvent;
  events: UseCalendarEventsType;
  calendar: UseCalendarType;
  eventDialogMode: Mode;
  handleCreateEvent(): void;
  handleSelectEvent(event: any): void;
  handleOpenEventCreate: () => void;
  handleStartEditingEvent: () => void;
  handleSaveUpdatedEvent: (event: any) => void;
  handleEndEvent(): void;
  handleDeleteEvent(): void;
  selectedEvent: any;
};

export const CalendarContext = createContext<CalendarContextType>({
  eventDialog: useDialogInitialState,
  createCalendarDialog: useDialogInitialState,
  event: useEventInitialState,
  events: useCalendarEventsInitialState,
  calendar: useCalendarInitialState,
  eventDialogMode: 'view',
  handleCreateEvent: () => {},
  handleSelectEvent: () => {},
  handleOpenEventCreate: () => {},
  handleStartEditingEvent: () => {},
  handleSaveUpdatedEvent: () => {},
  handleEndEvent: () => {},
  handleDeleteEvent: () => {},
  selectedEvent: {},
});
