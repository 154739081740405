import React, { useContext, useEffect } from 'react';
// Context
import { CalendarContext } from '../../context/CalendarContext';
// Components/ui
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ActionButtons,
  DatePicker,
  DescriptionTextField,
  LinkContactsSelect,
  ReminderNotifications,
  ShareUsersSelect,
  TitleTextField,
} from './components';
import DeleteIcon from '@mui/icons-material/Delete';
// Redux
import { fetchUsers } from 'redux/features/userSlice/userSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';

const EventDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    eventDialog: { open, handleClose },
    event: {
      titleField,
      descriptionField,
      date,
      shareWithUsers,
      notifications,
      linkContact,
    },
    eventDialogMode,
    selectedEvent,
    handleDeleteEvent,
  } = useContext(CalendarContext);

  const dialogTitle =
    eventDialogMode === 'edit'
      ? t('global.editEvent')
      : eventDialogMode === 'create'
      ? t('global.createEvent')
      : t('global.showEvent');

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  useEffect(() => {
    if (
      date.state.selectedStartDate.isAfter(date.state.selectedEndDate) &&
      !date.state.selectedStartDate.isSame(date.state.selectedEndDate, 'minute')
    ) {
      date.action.setError(true);
    } else {
      date.action.setError(false);
    }
  }, [date.state.selectedStartDate, date.state.selectedEndDate]);

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "50%",
          backgroundColor:
            //@ts-ignore
            (theme) => theme.palette.background.panel,
        },
      }}
      maxWidth={false}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {dialogTitle}
          <IconButton size="small" onClick={handleDeleteEvent} color="error">
            <DeleteIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <TitleTextField
          disabled={eventDialogMode === "view"}
          titleField={titleField}
        />
        {/* Event select field  */}
        <DescriptionTextField
          disabled={eventDialogMode === "view"}
          descriptionField={descriptionField}
        />
        <DatePicker
          disabled={eventDialogMode === "view"}
          selectedStartDate={date.state.selectedStartDate}
          selectedEndDate={date.state.selectedEndDate}
          handleSelectStart={date.action.handleSelect(
            date.action.setSelectedStartDate
          )}
          handleSelectEnd={date.action.handleSelect(
            date.action.setSelectedEndDate
          )}
          isError={date.state.error}
        />
        {/* Reminder notifications */}
        <ReminderNotifications
          disabled={eventDialogMode === "view"}
          notificationsField={notifications}
        />
        {/* Calendar select */}
        <LinkContactsSelect linkContactsHandler={linkContact} />
        <ShareUsersSelect
          disabled={eventDialogMode === "view"}
          shareWithUsersField={shareWithUsers}
        />
      </DialogContent>
      <ActionButtons isFinalized={selectedEvent?.finalized} />
    </Dialog>
  );
};

export default EventDialog;
