export const style = {
    calendar: {
        height: "100%",
        padding: "20px",
        width: "100%",
        zIndex: 1, // To avoid the calendar to be behind the header dropdown menus
        "& .rbc-calendar": {
          color: (theme) => theme.palette.text.primary,
          //@ts-ignore
          backgroundColor: (theme) =>  theme.palette.background.panel,
        },
        "& .rbc-calendar div span button": {
          color: (theme) => theme.palette.text.primary,
        },
        "& .rbc-calendar div span button.rbc-active": {
          color: (theme) => theme.palette.text.primary,
          //@ts-ignore
          backgroundColor: (theme) =>  theme.palette.background.dropdownMenu,
        },
        "& .rbc-calendar div span button:focus": {
          color: (theme) => theme.palette.text.primary,
          //@ts-ignore
          backgroundColor: (theme) => theme.palette.background.dropdownMenu,
        },
        "& .rbc-calendar div span button:hover": {
          color: (theme) => theme.palette.text.primary,
          //@ts-ignore
          backgroundColor: (theme) => theme.palette.background.dropdownMenu,
        },
        "& .rbc-calendar div div div div.rbc-off-range-bg": {
          //@ts-ignore
          backgroundColor: (theme) => theme.palette.background.dropdownMenu,
          backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))",
        },
        "& .rbc-calendar div div div div.rbc-today": {
          //@ts-ignore
          backgroundColor: (theme) => theme.palette.background.mainCenter,
          backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5))",
        },
        "& .rbc-calendar div div div.rbc-today": {
          //@ts-ignore
          backgroundColor: (theme) => theme.palette.background.mainCenter,
          backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5))",
        },
        "& .rbc-calendar div div div div.rbc-current-time-indicator": {
          //@ts-ignore
          backgroundColor: (theme) => theme.palette.mode === "dark" ? "#00ff05" : "#74ad31",
        },
    },
  };
  