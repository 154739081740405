import { useState } from 'react';
// Hooks
import useField, { useFieldInitialState } from 'hooks/useField';

export type UseCalendarType = {
  nameField: ReturnType<typeof useField>;
  color: string;
  handleColorChange: (color: string) => void;
};

export const useCalendarInitialState: UseCalendarType = {
  nameField: useFieldInitialState,
  color: '#ffffff',
  handleColorChange: () => {},
};

export const useCalendar = (): UseCalendarType => {
  const nameField = useField('text');
  const [color, setColor] = useState('#ffffff');

  const handleColorChange = (color: string) => {
    setColor(color);
  };

  return {
    nameField,
    color,
    handleColorChange,
  };
};
