// Hooks
import useField, { UseFieldType, useFieldInitialState } from 'hooks/useField';
import {
  UseEventDateType,
  useEventDate,
  useEventDateInitialDef,
} from './useEventDate';
import {
  UseNotificationsType,
  useNotifications,
  useNotificationsInitialDef,
} from './useNotifications';
import {
  UseLinkContactsType,
  useLinkContacts,
  useLinkContactsInitialDef,
} from './useLinkContacts';
import {
  UseShareWithUsersType,
  useShareWithUsers,
  useShareWithUsersInitialDef,
} from './useShareWithUsers';
// Types
import { AddActivityRequestData } from 'redux/features/activitiesSlice/types/AddActivityRequestData';
import { UserInfo } from '@trii/types/dist/Users';

export type UseEvent = {
  titleField: UseFieldType;
  descriptionField: UseFieldType;
  date: UseEventDateType;
  notifications: UseNotificationsType;
  shareWithUsers: UseShareWithUsersType;
  linkContact: UseLinkContactsType;
  resetFields: () => void;
  create: () => any;
};

export const useEventInitialState: UseEvent = {
  titleField: useFieldInitialState,
  descriptionField: useFieldInitialState,
  date: useEventDateInitialDef,
  notifications: useNotificationsInitialDef,
  shareWithUsers: useShareWithUsersInitialDef,
  linkContact: useLinkContactsInitialDef,
  resetFields: () => {},
  create: () => {},
};

const useEvent = (event?: any): UseEvent => {
  const titleField = useField('text', event?.title);
  const descriptionField = useField('text', event?.details);
  const date = useEventDate(event?.startAt, event?.endAt);
  const notifications = useNotifications(event?.notifications);
  const linkContact = useLinkContacts(event?.relatedContact?.id);
  const shareWithUsers = useShareWithUsers(
    event && getUserInfoIds(event.sharedWith)
  );

  function getUserInfoIds(userInfos: UserInfo[]): string[] {
    return userInfos.map((userInfo) => userInfo.id);
  }

  function resetFields() {
    titleField.actions.resetValue();
    descriptionField.actions.resetValue();
    date.action.resetDates();
    notifications.action.resetNotifications();
    linkContact.action.resetField();
    shareWithUsers.action.resetField();
  }

  function create(): AddActivityRequestData {
    return {
      title: titleField.attributes.value,
      details: descriptionField.attributes.value,
      startAt: date.state.selectedStartDate.toDate(),
      endAt: date.state.selectedEndDate.toDate(),
      contactId: linkContact.state.contactSelectedId,
      sharedWith: shareWithUsers.field.userIds,
      notifications: notifications.notifications,
      isPrivate: false,
      allDay: false,
      location: '',
      eventTypeId: '', //null
      calendarId: '', //null
      repeatId: '', //null
    };
  }

  return {
    titleField,
    descriptionField,
    date,
    notifications,
    linkContact,
    shareWithUsers,
    resetFields,
    create,
  };
};

export default useEvent;
