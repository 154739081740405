import { combineReducers } from '@reduxjs/toolkit';
// Reducers
import poolReducer from './features/poolSlice/poolSlice';
import sessionReducer from './features/sessionSlice/sessionSlice';
import spaceReducer from './features/spaceSlice/spaceSlice';
import spacesReducer from './features/spacesSlice/spacesSlice';
import userReducer from './features/userSlice/userSlice';
import activitiesReducer from './features/activitiesSlice/activitiesSlice';
import contactInfoReducer from './features/contactInfoSlice/contactInfoSlice';
import themeReducer from './features/themeSlice.js';

const rootReducer = combineReducers({
  Pool: poolReducer,
  Session: sessionReducer,
  Space: spaceReducer,
  Spaces: spacesReducer,
  User: userReducer,
  Theme: themeReducer,
  Activities: activitiesReducer,
  ContactInfo: contactInfoReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
