import { useContext } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { Typography, Fade } from '@mui/material';
import {
  StyledDatePickerContainer,
  StyledPickersContainer,
  StyledPickersDivider,
} from './styles';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

const DATE_FORMAT = 'DD/MM/YYYY HH:mm';

type DatePickerProps = {
  selectedStartDate: Dayjs;
  selectedEndDate: Dayjs;
  handleSelectStart: (date: Dayjs) => void;
  handleSelectEnd: (date: Dayjs) => void;
  isError: boolean;
  disabled: boolean;
};

const DatePicker = ({
  selectedStartDate,
  selectedEndDate,
  handleSelectStart,
  handleSelectEnd,
  isError,
  disabled,
}: DatePickerProps) => {
  const { t } = useTranslation();

  return (
    <StyledDatePickerContainer>
      <span
        style={{
          userSelect: 'none',
          marginBottom: 10,
        }}
      >
        {t('global.date')}
      </span>
      <StyledPickersContainer>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            disabled={disabled}
            format={DATE_FORMAT}
            ampm={false}
            slotProps={{
              textField: () => ({
                label: t('global.from'),
                size: 'small',
                className: 'smallInput',
              }),
            }}
            value={selectedStartDate}
            onChange={handleSelectStart}
            sx={{ alignSelf: 'baseline', flex: 1 }}
            minDate={dayjs()}
          />
          <StyledPickersDivider variant="body1">_</StyledPickersDivider>
          <DateTimePicker
            disabled={disabled}
            format={DATE_FORMAT}
            ampm={false}
            slotProps={{
              textField: () => ({
                label: t('global.to'),
                size: 'small',
                className: 'smallInput',
              }),
            }}
            value={selectedEndDate}
            onChange={handleSelectEnd}
            sx={{ alignSelf: 'baseline', flex: 1 }}
            minDate={selectedStartDate}
          />
        </LocalizationProvider>
      </StyledPickersContainer>
      <Fade in={isError}>
        <Typography color={'red'} variant="caption">
          {t('conversations.sidebar.events.errorDate')}
        </Typography>
      </Fade>
    </StyledDatePickerContainer>
  );
};

export default DatePicker;
