import { useState, useEffect, useContext } from 'react';
// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  fetchContactsData,
  selectContacts,
  selectContactsFetchStatus,
} from 'redux/features/contactInfoSlice/contactInfoSlice';
// Types
import { IContact } from '@trii/types/dist/Contacts';
import { Pagination } from 'redux/features/contactInfoSlice/types/Pagination';
import { UIEvent } from 'react';
// Context
import { Order } from 'redux/features/contactInfoSlice/types/Order';
import TableItemFormat from 'redux/features/contactInfoSlice/types/TableItemFormat';

export const useLinkContactsInitialDef = {
  state: {
    isLoading: false,
    contactSelectedId: null,
    search: '',
  },
  action: {
    resetField: () => {},
    handleSearch: (value: string) => {},
    handleScroll: (e: UIEvent<HTMLElement>) => {},
    handleSelectContact: (contact: IContact) => {},
  },
  contactList: [],
};
const PER_PAGE = 10;
const ORDER: Order = 'DESC';
const ORDER_COLUMN = 'name';
const FORMAT: TableItemFormat = 'IContact';
const OPERATOR: 'AND' | 'OR' = 'AND';

const DATA = {
  currentPage: 1,
  perPage: PER_PAGE,
  order: ORDER,
  orderColumn: ORDER_COLUMN,
  format: FORMAT,
  operator: OPERATOR,
  filter: [
    {
      column: 'Name',
      condition: '',
      value: '',
    },
  ],
  total: 0,
  lastPage: 0,
};

export type UseLinkContactsType = {
  state: {
    isLoading: boolean;
    contactSelectedId: string;
    search: string;
  };
  action: {
    resetField: () => void;
    handleSearch: (value: string) => void;
    handleScroll: (e: UIEvent<HTMLElement>) => void;
    handleSelectContact: (contactId: IContact) => void;
  };
  contactList: IContact[];
};

export function useLinkContacts(contactId?: string): UseLinkContactsType {
  const dispatch = useAppDispatch();
  // const { conversationSelected } = useContext(conversationsContext);
  const [search, setSearch] = useState<string>('');
  const [contacts, setContacts] = useState<IContact[]>([]);
  const [page, setPage] = useState<number>(1); // [1
  const contactData = useSelector(selectContacts);

  const [contactSelectedId, setContactSelectedId] = useState<string>(
    contactId || null
  );
  const [contactList, setContactList] = useState<IContact[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isNextPage, setIsNextPage] = useState<boolean>(false);
  const contactsFetchStatus = useSelector(selectContactsFetchStatus);
  const isLoading = contactsFetchStatus === 'loading';

  const handleSearch = async (value: string) => {
    setPage(1);
    const newPagination: Pagination = {
      ...DATA,
      currentPage: 1,
      filter: [
        {
          value,
          column: 'name',
          condition: '',
        },
      ],
    };
    setSearch(value);
    setIsNextPage(false);
    setCurrentPage(1);
    setContactList([]);

    if (value) {
      await dispatch(fetchContactsData(newPagination));
    }
  };

  const handleSelectContact = (contact: IContact) => {
    setSearch(contact?.name);
    setContactSelectedId(contact?.id);
    setContactList([]);
  };

  const handleScroll = async (e: UIEvent<HTMLElement>) => {
    const element = e.target as HTMLElement;
    const bottom = element.scrollHeight - element.scrollTop === element.clientHeight;
    const newPage = page + 1;
    const newPagination: Pagination = {
      ...DATA,
      currentPage: newPage,
      lastPage: contactData?.paginacion?.lastPage,
      total: contactData?.paginacion?.total,
      filter: [
        {
          value: search,
          column: 'name',
          condition: '',
        },
      ],
    };

    if (bottom) {
      setIsNextPage(true);
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      await dispatch(fetchContactsData(newPagination));
    }
  };

  function resetField() {
    // handleSelectContact(conversationSelected.contactInfo as IContact);
  }

  useEffect(() => {
    if (contacts) {
      if (isNextPage) {
        setContactList([...contactList, ...contacts]);
      } else {
        setContactList(contacts);
      }
    }
  }, [contacts]);

  useEffect(() => {
    // if (!contactId) handleSelectContact(conversationSelected?.contactInfo as IContact);
  }, []);

  useEffect(() => {
    if (contactData) {
      const { contactos } = contactData;
      setContacts(contactos);
    }
  }, [contactData]);

  return {
    action: {
      resetField,
      handleSearch,
      handleScroll,
      handleSelectContact,
    },
    state: { isLoading, contactSelectedId, search },
    contactList,
  };
}
