import { useEffect } from 'react';
// Components/ui
import { Box } from '@mui/material';
import ViewContainer from 'features/Layout/ViewContainer';
import {
  Calendar,
  CreateCalendarDialog,
  EventDialog,
  OptionsColumn,
} from './components';
// Context Provider
import CalendarContextProvider from './context/CalendarContextProvider';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { fetchActivities } from 'redux/features/activitiesSlice/activitiesSlice';

const CalendarView = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchActivities());
  }, []);

  return (
    <CalendarContextProvider>
      <ViewContainer>
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            backgroundColor: (theme) => theme.palette.background.default,
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.25)',
            borderRadius: '3px',
          }}
        >
          <CreateCalendarDialog />
          <EventDialog />
          <OptionsColumn />
          <Calendar />
        </Box>
      </ViewContainer>
    </CalendarContextProvider>
  );
};

export default CalendarView;
