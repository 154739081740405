import { useState } from 'react';

export interface UseDialog {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}

export const useDialogInitialState: UseDialog = {
  open: false,
  handleOpen: () => {},
  handleClose: () => {},
};

export const useDialog = (): UseDialog => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return { open, handleOpen, handleClose };
};
