// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import { InputContainer } from '../../layout';
import { Box, LinearProgress } from '@mui/material';
import { ContactSearchBar, ContactSearchResults } from './components';
import { SelectPrimaryContainer, SelectSecondaryContainer } from './styles';
// Types
import { UseLinkContactsType } from 'features/Views/CalendarView/hooks/useLinkContacts';

type LinkContactsSelectProps = {
  linkContactsHandler: UseLinkContactsType;
};
const LinkContactsSelect = ({ linkContactsHandler }: LinkContactsSelectProps) => {
  const { t } = useTranslation();
  const {
    contactList,
    action: { handleScroll, handleSearch, handleSelectContact },
    state: { contactSelectedId, isLoading, search },
  } = linkContactsHandler;
  const containerHeight = contactList.length > 0 ? 230 : '100%';

  return (
    <InputContainer title={t('global.linkContacts')}>
      <SelectPrimaryContainer height={containerHeight}>
        <SelectSecondaryContainer>
          <Box position="absolute" top="2rem" width="100%">
            {isLoading && (
              <LinearProgress
                sx={{
                  borderRadius: 1,
                }}
              />
            )}
          </Box>
          <ContactSearchBar handleSearch={handleSearch} search={search} />
          {contactList.length > 0 && (
            <ContactSearchResults
              handleScroll={handleScroll}
              contactList={contactList}
              handleSelectContact={handleSelectContact}
              contactSelectedId={contactSelectedId}
              search={search}
            />
          )}
        </SelectSecondaryContainer>
      </SelectPrimaryContainer>
    </InputContainer>
  );
};

export default LinkContactsSelect;
