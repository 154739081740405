import { useEffect, useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
import { selectActivities } from 'redux/features/activitiesSlice/utils/selectors';

export type UseCalendarEventsType = {
  events: any[];
  setEvents: React.Dispatch<React.SetStateAction<any[]>>;
  adaptEvent: (event: any) => any;
};

export const useCalendarEventsInitialState: UseCalendarEventsType = {
  events: [],
  setEvents: () => {},
  adaptEvent: () => {},
};

export const useCalendarEvents = (): UseCalendarEventsType => {
  const stateEvents = useSelector(selectActivities);

  const [events, setEvents] = useState([]);

  function adaptEvent(event) {
    const { startAt, endAt, ...rest } = event;
    return {
      ...rest,
      start: startAt,
      end: endAt,
    };
  }

  function adaptAPIEvents(events) {
    return events.map((event) => adaptEvent(event));
  }

  useEffect(() => {
    setEvents(adaptAPIEvents(stateEvents));
  }, [stateEvents]);

  return {
    events,
    setEvents,
    adaptEvent,
  };
};
