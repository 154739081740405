import { useTranslation } from 'react-i18next';
// Components/ui
import { TextField, InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';
// Context
import { useContext } from 'react';
import { CalendarContext } from 'features/Views/CalendarView/context/CalendarContext';

interface Props {
  handleSearch: (value: string) => void;
  search: string;
}
const ContactSearchBar = ({ search, handleSearch }: Props) => {
  const { eventDialogMode } = useContext(CalendarContext);
  const { t } = useTranslation();

  return (
    <TextField
      fullWidth
      disabled={eventDialogMode === 'view'}
      className="contacts-smallInput"
      placeholder={t('global.searchContacts')}
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search
              fontSize="small"
              sx={{
                color: (theme) => theme.palette.text.disabled,
              }}
            />
          </InputAdornment>
        ),
      }}
      value={search}
      onChange={(e) => handleSearch(e.target.value)}
    />
  );
};

export default ContactSearchBar;
