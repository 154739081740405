import { Dispatch, SetStateAction, useState, useEffect } from 'react';
// Dayjs
import dayjs, { Dayjs } from 'dayjs';

export const useEventDateInitialDef = {
  state: { error: false, selectedStartDate: null, selectedEndDate: null },
  action: {
    setError: () => {},
    setSelectedStartDate: () => {},
    setSelectedEndDate: () => {},
    handleSelect: () => () => {},
    resetDates: () => {},
  },
};

export type UseEventDateType = {
  state: { error: boolean; selectedStartDate: Dayjs; selectedEndDate: Dayjs };
  action: {
    setError: Dispatch<SetStateAction<boolean>>;
    setSelectedStartDate: Dispatch<SetStateAction<Dayjs>>;
    setSelectedEndDate: Dispatch<SetStateAction<Dayjs>>;
    handleSelect: (
      setState: Dispatch<SetStateAction<Dayjs>>
    ) => (date: Dayjs) => void;
    resetDates: () => void;
  };
};

export function useEventDate(startDate?: Date, endDate?: Date): UseEventDateType {
  const todayDate: Dayjs = dayjs();
  const [selectedStartDate, setSelectedStartDate] = useState(
    startDate ? dayjs(startDate) : todayDate
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    endDate ? dayjs(endDate) : todayDate
  );
  const [error, setError] = useState(false);

  const handleSelect =
    (setState: Dispatch<SetStateAction<Dayjs>>) => (date: Dayjs) => {
      setState(date);
    };

  const resetDates = () => {
    setSelectedStartDate(todayDate);
    setSelectedEndDate(todayDate);
  };

  useEffect(() => {
    if (startDate && endDate) {
      setSelectedStartDate(dayjs(startDate));
      setSelectedEndDate(dayjs(endDate));
    }
  }, [startDate, endDate]);

  return {
    state: { error, selectedStartDate, selectedEndDate },
    action: {
      setError,
      setSelectedStartDate,
      setSelectedEndDate,
      handleSelect,
      resetDates,
    },
  };
}
