import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
// Types
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';
import { IEvent } from '@trii/types/dist/Calendar';
import type { RootState } from 'redux/store';
import type { ActivitiesSlice } from './types/ActivitiesSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AddActivityRequestData } from './types/AddActivityRequestData';
import type { UpdatedActivityRequestData } from './types/UpdateActivityRequestData';
// Utils
import activitiesSliceService from './utils/activitiesSliceService';
import { initRequestData } from 'redux/functions/initRequestData';
import { ActivitiesTableColumnName } from './types/ActivitiesTableColumnName';
import { SortOrder } from 'redux/types/SortOrder';

const initialState: ActivitiesSlice = {
  activities: [],
  status: {
    fetch: 'idle',
    add: 'idle',
    delete: 'idle',
    updateEnding: 'idle',
    update: 'idle',
  },
};

export const fetchActivities = createAsyncThunk<IEvent[], void>(
  'activities/fetchActivities',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { uid } = state.User.user;
    const { jwtToken, URL_CALENDAR } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const fetchedActivities = await activitiesSliceService.fetchActivities(
      jwtToken,
      `${URL_CALENDAR}/events?userId=${uid}`
    );

    return fetchedActivities;
  }
);

export const addActivity = createAsyncThunk<IEvent, AddActivityRequestData>(
  'activities/addActivity',
  async (activity, { dispatch }) => {
    const { jwtToken, URL_CALENDAR } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    const newFile = await activitiesSliceService.addActivity(
      jwtToken,
      `${URL_CALENDAR}/events`,
      activity
    );

    return newFile;
  }
);

export const deleteActivity = createAsyncThunk<string, string>(
  'activities/deleteActivity',
  async (activityId, { dispatch }) => {
    const { jwtToken, URL_CALENDAR } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    await activitiesSliceService.deleteActivity(
      jwtToken,
      `${URL_CALENDAR}/events/${activityId}`
    );

    return activityId;
  }
);

export const endActivity = createAsyncThunk<IEvent, string>(
  'activities/endActivity',
  async (activityId, { dispatch }) => {
    const { jwtToken, URL_CALENDAR } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const endedActivity = { finalized: true };
    const updatedActivity = await activitiesSliceService.updateActivity(
      jwtToken,
      `${URL_CALENDAR}/events/${activityId}`,
      endedActivity
    );

    return updatedActivity;
  }
);

export const updateActivity = createAsyncThunk<IEvent, UpdatedActivityRequestData>(
  'activities/updateActivity',
  async (updateActivityRequestData, { dispatch }) => {
    const { jwtToken, URL_CALENDAR } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;
    const { activityId, updatedActivity } = updateActivityRequestData;

    const activity = await activitiesSliceService.updateActivity(
      jwtToken,
      `${URL_CALENDAR}/events/${activityId}`,
      updatedActivity
    );

    return activity;
  }
);

const activitiesSlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchActivities.pending, (state, action) => {
        state.status.fetch = 'loading';
      })
      .addCase(fetchActivities.fulfilled, (state, action) => {
        state.status.fetch = 'succeeded';
        console.log('fetch activities action.payload', action.payload);

        state.activities = action.payload;
      })
      .addCase(addActivity.pending, (state, action) => {
        state.status.add = 'loading';
      })
      .addCase(addActivity.fulfilled, (state, action) => {
        state.activities.push(action.payload);
        state.status.add = 'idle';
      })
      .addCase(addActivity.rejected, (state, action) => {
        state.status.add = 'idle';
        console.log(action.error.message);
      })
      .addCase(deleteActivity.pending, (state, action) => {
        state.status.delete = 'loading';
      })
      .addCase(deleteActivity.fulfilled, (state, action) => {
        const filteredActivities = state.activities.filter(
          (activity) => activity.id !== action.payload
        );

        state.status.delete = 'idle';
        state.activities = filteredActivities;
      })
      .addCase(endActivity.pending, (state, action) => {
        state.status.updateEnding = 'loading';
      })
      .addCase(endActivity.fulfilled, (state, action) => {
        const updatedActivity = action.payload;
        const updatedActivities = state.activities.map((activity) => {
          if (activity.id !== updatedActivity.id) return activity;
          return updatedActivity;
        });

        state.status.updateEnding = 'idle';
        state.activities = updatedActivities;
      })
      .addCase(updateActivity.pending, (state, action) => {
        state.status.update = 'loading';
      })
      .addCase(updateActivity.fulfilled, (state, action) => {
        const updatedActivity = action.payload;
        const updatedActivities = state.activities.map((activity) => {
          if (activity.id !== updatedActivity.id) return activity;
          return updatedActivity;
        });
        state.status.update = 'idle';
        state.activities = updatedActivities;
      });
  },
});

export default activitiesSlice.reducer;
