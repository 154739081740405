import React, { useContext } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
} from '@mui/material';
import { MuiColorInput } from 'mui-color-input';
// Context
import { CalendarContext } from '../../context/CalendarContext';

const CreateCalendarDialog: React.FC = () => {
  const {
    createCalendarDialog: { handleClose, open },
    calendar,
  } = useContext(CalendarContext);
  const { t } = useTranslation();

  const handleSubmit = () => {
    // Handle form submission logic here
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor:
            //@ts-ignore
            (theme) => theme.palette.background.panel,
        },
      }}
    >
      <DialogTitle>{t("global.createCalendar")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              label={t("global.name")}
              name="name"
              {...calendar.nameField.attributes}
            />
          </Grid>
          <Grid item xs={12}>
            <MuiColorInput
              value={calendar.color}
              onChange={calendar.handleColorChange}
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {t("global.cancel")}
        </Button>
        <Button onClick={handleSubmit} color="secondary">
          {t("global.create")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCalendarDialog;
