import { useContext, useState } from 'react';
// Context
import { CalendarContext } from '../../context/CalendarContext';
// Components/ui
import AddBoxIcon from '@mui/icons-material/AddBox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
// Translation
import { useTranslation } from 'react-i18next';
import { style } from './style';

const OptionsColumn = () => {
  const { t } = useTranslation();

  const { handleOpenEventCreate, createCalendarDialog } =
    useContext(CalendarContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  return (
    <Box sx={style.container}>
      <div>
        <Button
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          variant="contained"
          startIcon={<AddBoxIcon />}
        >
          {t("global.create")}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            sx: {
              //@ts-ignore
              backgroundColor: (theme) => theme.palette.background.panel,
            },
          }}
        >
          <MenuItem onClick={handleOpenEventCreate}>
            {t("global.event")}
          </MenuItem>
          <MenuItem onClick={createCalendarDialog.handleOpen}>
            {t("global.calendar")}
          </MenuItem>
        </Menu>
      </div>
      {/* Date picker */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar />
      </LocalizationProvider>
      {/* Acordion */}
      <div>
        <Accordion
          sx={{
            backgroundColor: (theme) => theme.palette.background.default,
            backgroundImage:
              "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
            boxShadow: (theme) => theme.shadows[3],
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="body2">My calendars</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ display: "flex", flexDirection: "column" }}>
            <FormControlLabel
              control={<Checkbox size="small" defaultChecked />}
              label={<Typography variant="caption">Familia</Typography>}
            />
            <FormControlLabel
              control={<Checkbox size="small" defaultChecked />}
              label={<Typography variant="caption">Amigos</Typography>}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
        sx={{
          backgroundColor: theme => theme.palette.background.default,
          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
          boxShadow: theme => theme.shadows[3],
        }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography variant="body2">Other calendars</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ display: "flex", flexDirection: "column" }}>
            <FormControlLabel
              control={<Checkbox size="small" defaultChecked />}
              label={<Typography variant="caption">Educacion vial</Typography>}
            />
            <FormControlLabel
              sx={{ fontSize: "12px" }}
              control={<Checkbox size="small" defaultChecked />}
              label={
                <Typography variant="caption">Festivos Argentina</Typography>
              }
            />
          </AccordionDetails>
        </Accordion>
      </div>
    </Box>
  );
};

export default OptionsColumn;
