import { useState } from 'react';
import { CalendarContext } from './CalendarContext';
// Types
import { CalendarContextType } from './CalendarContext';
import { Mode } from './types/Mode';
import { UpdatedActivityRequestData } from 'redux/features/activitiesSlice/types/UpdateActivityRequestData';
// Hooks
import useEvent from '../hooks/useEvent';
import { useDialog } from '../hooks/useDialog';
import { useCalendar } from '../hooks/useCalendar';
import { useCalendarEvents } from '../hooks/useCalendarEvents';
// Redux
import {
  addActivity,
  deleteActivity,
  endActivity,
  updateActivity,
} from 'redux/features/activitiesSlice/activitiesSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';

interface Props {
  children: React.ReactNode;
}

const CalendarContextProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();

  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [eventDialogMode, setEventDialogModeMode] = useState<Mode>('view');

  const eventDialog = useDialog();
  const createCalendarDialog = useDialog();
  const event = useEvent(selectedEvent);
  const events = useCalendarEvents();
  const calendar = useCalendar();

  function handleOpenEventCreate() {
    setEventDialogModeMode('create');

    event.resetFields();
    eventDialog.handleOpen();
  }
  function handleStartEditingEvent() {
    setEventDialogModeMode('edit');
  }
  function handleSelectEvent(event: any) {
    setSelectedEvent(event);
    setEventDialogModeMode('view');

    eventDialog.handleOpen();
  }

  async function handleCreateEvent() {
    const newEvent = event.create();

    await dispatch(addActivity(newEvent));

    event.resetFields();
    eventDialog.handleClose();
  }
  async function handleSaveUpdatedEvent() {
    const updatedEvent = event.create();
    const updateEventData: UpdatedActivityRequestData = {
      updatedActivity: updatedEvent,
      activityId: selectedEvent.id,
    };

    await dispatch(updateActivity(updateEventData));

    eventDialog.handleClose();
  }
  async function handleDeleteEvent() {
    await dispatch(deleteActivity(selectedEvent.id));

    eventDialog.handleClose();
  }
  async function handleEndEvent() {
    await dispatch(endActivity(selectedEvent.id));

    eventDialog.handleClose();
  }

  const value: CalendarContextType = {
    eventDialog,
    eventDialogMode,
    createCalendarDialog,
    event,
    calendar,
    events,
    handleCreateEvent,
    handleSelectEvent,
    handleOpenEventCreate,
    handleStartEditingEvent,
    handleSaveUpdatedEvent,
    handleEndEvent,
    handleDeleteEvent,
    selectedEvent,
  };

  return (
    <CalendarContext.Provider value={value}>{children}</CalendarContext.Provider>
  );
};

export default CalendarContextProvider;
