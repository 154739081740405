export const style = {
  container: {
    height: '100%',
    width: '25%',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    borderRight: '1px solid lightgrey',
    borderColor: (theme) => theme.palette.divider,
    color: (theme) => theme.palette.text.primary,
  },
};
