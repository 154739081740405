import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
// Components/ui
import { BasicInputContainer } from '../../layout';
import { TextField } from '@mui/material';
// Types
import { UseFieldType } from 'hooks/useField';

type TitleTextFieldProps = {
  titleField: UseFieldType;
  disabled: boolean;
};

const TitleTextField = ({ titleField, disabled }: TitleTextFieldProps) => {
  const { t } = useTranslation();
  return (
    <BasicInputContainer name={t('global.title')}>
      <TextField
        disabled={disabled}
        autoFocus
        type="text"
        fullWidth
        variant="outlined"
        size="small"
        {...titleField.attributes}
      />
    </BasicInputContainer>
  );
};

export default TitleTextField;
