// Types
import { INavIcon } from '../types/types';
// Ui
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const iconsData: INavIcon[] = [
  {
    title: 'global.calendar',
    to: '/a/calendar/calendar',
    icon: <CalendarMonthIcon />,
  },

  {
    title: 'global.calendar',
    to: '/a/calendar/Calendar',
    icon: <CalendarMonthIcon />,
    isDevelopment: true,
  },
];

export { iconsData };
