import { Routes as ReactRoutes } from 'react-router-dom';
import { Route } from 'react-router-dom';
/** Components */
import MainLayout from './features/Layout/MainLayout';
import { CalendarView } from 'features/Views';
import CalendarViewIFRAME from 'features/Views/CalendarViewIFRAME';

const Routes = () => {
  return (
    <MainLayout>
      <ReactRoutes>
        <Route path="/a/calendar/calendar" caseSensitive={true} element={<CalendarViewIFRAME />} />
        <Route path="/a/calendar/Calendar" caseSensitive={true} element={<CalendarView />} />
        {/* <Route path="*" element={<Dashboard />} />
        <Route path="/a/marketing/dashboard" element={<Dashboard />} />
        <Route path="/a/marketing/system" element={<SystemMVC />} /> */}
      </ReactRoutes>
    </MainLayout>
  );
};

export default Routes;
